<script>
import {mapActions} from "vuex";

export default {
  name: "SberSummaryWidget",
  data() {
    return {
      summaryList: [],
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    ...mapActions(['loading']),
    async loadData() {
      this.loading()

      try {
        const response = await axios.get('/api/sber-widget')
        this.summaryList = response?.data
      } catch (e) {
        console.log(e)
      }

      this.loading(false)
    },
    formatNumber(number) {
      return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(number).replace(',', '.')
    },
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
        <div>Остатки на счетах Сбербанка</div>
      <v-spacer/>
        <div>
          <v-btn @click="loadData" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>

    </v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <thead>
        <tr>
          <th>Пиццерия</th>
          <th>Сумма</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in summaryList">
          <td>{{ data?.pizzeria_name }}</td>
          <td>{{ formatNumber(data?.total_closing_balance) }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="sass">

</style>