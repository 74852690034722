<template>
  <v-app>
    <v-card v-if="surveyReminder"
            style="position: fixed; top: 0; left: 50%; transform: translateX(-50%); width: 100vw; z-index: 4;">
      <v-card-title style="background-color: rgba(243,252,16,0.6)" class="justify-center text-break">
        Необходимо заполнить обращение на трудоустройство. Без его отправки - отработанные часы не будут учтены!!!
        <v-btn @click="openSurveyDialog" class="mx-5">Заполнить</v-btn>
        <v-btn @click="surveyReminder=false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <user-survey-dialog v-if="!this?.user?.done_survey && this?.user" :dialog="userSurveyDialog"
                        @close="closeSurveyDialog"></user-survey-dialog>
    <v-overlay style="z-index: 900 !important;" :value="isLoading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer v-if="authenticated && showContent  && !mobileView"
                         :mini-variant="drawer"
                         fixed
                         app
                         mobile-breakpoint="0"
                         style="z-index: 901 !important;"
    >
      <v-list>
        <v-list-item class="pl-2">
          <v-list-item-avatar color="orange">
            <img :src="user.avatar" alt="">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ user.display_name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>


      <div class="text-center">
        <v-app-bar-nav-icon class="px-0 py-5" @click="drawer = !drawer"></v-app-bar-nav-icon>
      </div>

      <v-divider></v-divider>

      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Рабочий стол</v-list-item-title>
      </v-list-item>


      <v-list-item to="/tickets">
        <v-list-item-icon>
          <v-icon>mdi-chat-processing-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Обращения</v-list-item-title>
      </v-list-item>

      <v-list-item to="/tickets/create">
        <v-list-item-icon>
          <v-icon>mdi-chat-plus-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Создать обращение</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="[0, 1, 2, 8, 964].includes(user.id)" to="/admin/ticket-statistic">
        <v-list-item-icon>
          <v-icon>mdi-chat-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="text-wrap">Статистика по обращениям</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/admin/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Администрирование</v-list-item-title>
      </v-list-item>

      <v-list-item to="/userDashboard"
                   v-else-if="canSeeUsers
	    || canSeeAccounterKitchenSalary
	    || canSeeKLN
			|| canCashReport
			|| canCashStat
			|| isBuh
			|| canChangePolls">
        <v-list-item-icon>
          <v-icon>mdi-account-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Дэшборд</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isLinear" :to="klnRoute">
        <v-list-item-icon>
          <v-icon>mdi-card-bulleted-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>КЛН</v-list-item-title>
      </v-list-item>

      <v-hover v-slot="{ isHovering }">
        <v-list-item
            @click="knowledgeBaseAuth"
            :class="{ 'hovered-item': isHovering }"
        >
          <v-list-item-icon>
            <v-icon>mdi-library-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Библиотека</v-list-item-title>
        </v-list-item>
      </v-hover>

      <!--      <v-list-item v-if="canSeeUsers" to="/admin/users">-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-account-group</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>Пользователи</v-list-item-title>-->
      <!--      </v-list-item>-->

      <!--      <v-list-item v-if="canSeeAccounterKitchenSalary" to="/accounter-kitchen-salary">-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-account-cash</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>Зарплата кухни</v-list-item-title>-->
      <!--      </v-list-item>-->

      <!--      <v-list-item v-if="canSeeAccounterKitchenSalary" to="/admin/sber-transactions">-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-cash-fast</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>Сбер: транзакции</v-list-item-title>-->
      <!--      </v-list-item>-->

      <!--      <v-list-item v-if="canSeeKLN" :to="klnRoute">-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-card-bulleted-outline</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>КЛН</v-list-item-title>-->
      <!--      </v-list-item>-->

      <!--      <v-list-item-->
      <!--          v-if="canCashReport || canCashStat" :to="canCashStat?'/cash_stat':'/create_cash_report'"-->
      <!--      >-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-cash</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>Учет наличных</v-list-item-title>-->
      <!--      </v-list-item>-->

      <!--      <v-list-item-->
      <!--          v-if="isBuh" :to="'/admin/contractors'"-->
      <!--      >-->
      <!--        <v-list-item-icon>-->
      <!--          <v-icon>mdi-card-account-details-outline</v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-title>Контрагенты</v-list-item-title>-->
      <!--      </v-list-item>-->

      <v-list-item to="/notifications">
        <v-list-item-icon>
          <v-icon>mdi-bell</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Уведомления</v-list-item-title>
      </v-list-item>

      <v-list-item @click="doLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Выйти
        </v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
    <v-main v-if="showContent">

      <!-- BEGIN баннер -->
      <v-banner
          v-if="user?.prev_user_id"
          single-line
          class="teal lighten-4">
        <v-icon left color="teal">mdi-account-switch</v-icon>
        <strong>{{ user?.prev_user_display_name }}</strong>,
        вы авторизовались под другим пользователем
        <template #actions>
          <v-btn @click="doLoginBack" color="teal lighten-2 white--text">
            <v-icon left>mdi-account-convert</v-icon>
            Вернуться
          </v-btn>
        </template>
      </v-banner>
      <!-- END баннер -->

      <v-dialog
          v-if="authenticated && showContent"
          v-model="drawer2"
          scrollable
          :fullscreen="mobileView"
          max-width="500"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title>Какие-то настройки когда они нужны</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">

            <p class="lead">Эта штука пока не работает, можно закрыть её =)</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab alias atque beatae cum dolor exercitationem
              laborum veritatis. Animi, aperiam at dignissimos eius exercitationem ipsum libero, nesciunt qui
              repellendus tempore ut!</p>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                color="blue darken-1"
                text
                @click="drawer2 = false"
            >
              Закрыть
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="drawer2 = false"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view @setBreadcrumbs="setBreadcrumbs"/>
    </v-main>
    <v-footer fixed padless v-if="authenticated  && showContent && mobileView" app>
      <v-card class="blue-grey darken-3 text-center" flat tile width="100%">
        <v-card-text class="px-0 justify-space-around d-flex py-2">

          <v-btn
              fab
              dar
              color="blue-grey"
              height="40px"
              to="/tickets/create"
              width="40px">
            <v-icon color="white">
              mdi-chat-plus-outline
            </v-icon>
          </v-btn>

          <v-btn
              fab
              dark
              color="blue-grey"
              height="40px"
              to="/tickets"
              width="40px">
            <v-icon color="white">
              mdi-chat-processing-outline
            </v-icon>
          </v-btn>

          <v-btn
              v-if="[0, 1, 2, 8, 964].includes(user.id)"
              to="/admin/ticket-statistic"
              fab
              dark
              color="blue-grey"
              height="40px"
              width="40px">
            <v-icon color="white">
              mdi-chat-question-outline
            </v-icon>
          </v-btn>

          <v-btn fab
                 dark
                 color="blue-grey"
                 height="40px"
                 width="40px"
                 href="/">
            <user-chip size="40" :user="user" mobileCollapse/>
          </v-btn>

          <v-btn
              v-if="isAdmin"
              fab
              dark
              color="blue-grey"
              height="40px"
              to="/admin/dashboard"
              width="40px">
            <v-icon color="white">
              mdi-cog
            </v-icon>
          </v-btn>
          <v-btn
              v-else-if="canSeeUsers
								    || canSeeAccounterKitchenSalary
								    || canSeeKLN
										|| canCashReport
										|| canCashStat
										|| isBuh
										|| canChangePolls
										|| isRegionalManager"
              fab
              dark
              color="blue-grey"
              height="40px"
              to="/userDashboard"
              width="40px">
            <v-icon color="white">
              mdi-account-cog
            </v-icon>
          </v-btn>

          <v-btn
              fab
              dark
              color="blue-grey"
              height="40px"
              to="/notifications"
              width="40px">
            <v-icon color="white">
              mdi-bell
            </v-icon>
          </v-btn>

          <v-btn
              fab
              dark
              color="blue-grey"
              height="40px"
              width="40px"
              v-if="isLinear" :to="klnRoute">
            <v-icon>mdi-card-bulleted-outline</v-icon>
          </v-btn>
          <!--					<v-btn-->
          <!--							fab-->
          <!--							dark-->
          <!--							color="blue-grey"-->
          <!--							height="40px"-->
          <!--							width="40px"-->
          <!--							v-if="canCashReport || canCashStat" :to="canCashStat?'/cash_stat':'/create_cash_report'"-->
          <!--					>-->
          <!--						<v-icon color="white">-->
          <!--							mdi-cash-->
          <!--						</v-icon>-->
          <!--					</v-btn>-->
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import UserSurveyDialog from "@/components/dialogs/UserSurveyDialog.vue";
import SberSummaryWidget from "@/components/widgets/SberSummaryWidget.vue";

export default {
  name: 'App',
  components: {
    SberSummaryWidget,
    UserSurveyDialog,
    UserChip: () => import("@/pages/profile/UserChip"),
  },
  data: () => ({
    surveyReminder: false,
    drawer: true,
    drawer2: false,
    showContent: false,
    groups: [],
    title: '',
    breadcrubms: [],
  }),
  computed: {
    ...mapGetters(['authenticated', 'user', 'isLoading', 'userSurveyDialog',
      'isAdmin', 'isRegionalManager', 'canCashStat', 'canCashReport', 'canSeeUsers',
      'canSeeAccounterKitchenSalary', 'canSeeKLN', 'isBuh', "isLinear", 'canChangePolls', "isFinance"]),
    klnRoute() {
      return {name: 'AdminKLN', query: {pizzeriaId: this.user.pizzeria_id}}
    }
  },
  watch: {
    async '$route.path'() {
      try {
        await this.me()
      } catch (e) {
        console.error(e)
      }
    },
  },
  async created() {
  },
  async mounted() {
    if (!this.authenticated) {
      try {
        await this.autoLogin()
      } catch (e) {
        console.error(e)
      }
    }
    this.showContent = true
    this.setBreadcrumbs()
    this.surveyReminder = !this?.user?.done_survey && this?.user
    const categoryList = this.$store?.state?.categoriesList
    const budgetCategories = this?.$store?.state?.budgetCategoriesList
    const groupList = this?.$store?.state?.groupsList
    const usersList = this.$store?.state?.usersList
    const pizzeriasList = this.$store?.state?.pizzeriasList
    const rolesList = this.$store?.state?.rolesList
    const contractorsList = this.$store?.state?.contractorsList

    // Получаем время последней загрузки из localStorage или Vuex store
    const lastUpdated = this.$store?.state?.lastUpdated;
    // Проверяем, прошло ли более часа (3600000 миллисекунд)
    const oneHour = 3600000;
    const currentTime = Date.now();
    if (!categoryList || !groupList || !usersList || !pizzeriasList || !rolesList || !lastUpdated || (!contractorsList && (this.isFinance || this.isAdmin)) || (!budgetCategories && (this.isFinance || this.isAdmin)) || (currentTime - lastUpdated > oneHour)) {
      await this.$store.dispatch('updateLists');
    }


  },
  methods: {
    ...mapActions(['me', 'logout', 'autoLogin', 'loginAsBack', 'setUserSurveyDialog']),
    openSurveyDialog() {
      this.setUserSurveyDialog(true)
    },
    closeSurveyDialog() {
      this.setUserSurveyDialog(false)
    },
    setBreadcrumbs(b = false) {
      this.breadcrubms = []
      let route = this.$router.currentRoute.name
      switch (route) {
        default:
          break
        case 'Tickets':
          this.title = 'Обращения'
          this.breadcrubms.push({text: 'Обращения'})
          break
        case 'Ticket':
          this.title = 'Обращения'
          this.breadcrubms.push({to: {name: 'Tickets'}, text: 'Обращения'})
          break
        case 'Users':
          this.title = 'Пользователи'
          this.breadcrubms.push({text: 'Пользователи'})
          break
        case 'User':
          this.title = 'Пользователи'
          this.breadcrubms.push({to: '/admin/users', text: 'Пользователи'})
          break
        case 'Notifications':
        case 'Notification':
          this.title = 'Уведомления'
          this.breadcrubms.push({to: this.$router.currentRoute.path, text: 'Уведомления'})
          break
      }
      if (b)
        this.breadcrubms.push(b)
    },
    async doLoginBack() {
      await this.loginAsBack()
      if (this.$route.path !== '/admin/users') this.$router.push('/admin/users')
    },
    async doLogout() {
      await this.logout()
      this.drawer = false
      window.location.reload()
    },
    async knowledgeBaseAuth() {
      try {
        const response = await axios.post('/api/knowledgeAuth', {}, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        // Редирект на внешний сайт с токеном
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Ошибка при аутентификации:', error);
      }
      // // this.$router.push('https://lib.lookin.team/');
      // window.open('https://lib.lookin.team/', '_blank');
    },
    async klnAuth() {
      try {
        const response = await axios.post('/api/klnAuth', {}, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        // Редирект на внешний сайт с токеном
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Ошибка при аутентификации:', error);
      }
      // // this.$router.push('https://lib.lookin.team/');
      // window.open('https://lib.lookin.team/', '_blank');
    },
  },
}
</script>
<style>
* {
  box-sizing: border-box;
}

.custom-shadow-table .v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
