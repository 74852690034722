<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import List from "@/pages/admin/notifiers/List.vue";
import MessagesResource from "@/resources/MessagesResource";
import notify from "@/components/notifications/notify";
import UserSurveysResource from "@/resources/UserSurveysResource";
import UsersResource from "@/resources/UsersResource";

export default defineComponent({
  name: "UserSurveyDialog",
  components: {List},
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      survey: {},
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['loading', 'me']),
    async loadSurvey(stop = null) {
      this.loading()
      try {
        const {data} = await UserSurveysResource.list({user_id: this.user.id})
        if (data) {
          this.survey.id = data.id
          this.$set(this.survey, 'passport_1_file', data.passport_1_file)
          this.$set(this.survey, 'passport_2_file', data.passport_2_file)
          this.$set(this.survey, 'med_book_1_file', data.med_book_1_file)
          this.$set(this.survey, 'med_book_2_file', data.med_book_2_file)
          this.$set(this.survey, 'med_book_3_file', data.med_book_3_file)
          this.$set(this.survey, 'med_book_4_file', data.med_book_4_file)
          this.$set(this.survey, 'inn_file', data.inn_file)
          this.$set(this.survey, 'snils_file', data.snils_file)
          this.$set(this.survey, 'std_r_file', data.std_r_file)

          let formattedData = data.work_book_files == null
              ? undefined
              : (Array.isArray(data.work_book_files) ? data.work_book_files : [data.work_book_files]);
          this.$set(this.survey, 'work_book_files', formattedData)

          formattedData = data.war_book_files == null
              ? undefined
              : (Array.isArray(data.war_book_files) ? data.war_book_files : [data.war_book_files]);
          this.$set(this.survey, 'war_book_files', formattedData)
          this.$set(this.survey, 'bank_cred_file', data.bank_cred_file)
          this.$set(this.survey, 'middle_diploma_file', data.middle_diploma_file)
          this.$set(this.survey, 'work_application_file', data.work_application_file)
          this.$set(this.survey, 'parent_approval_file', data.parent_approval_file)
          this.$set(this.survey, 'child_birth_cert_files', data.child_birth_cert_files)
          this.$set(this.survey, 'child_tax_deduction_file', data.child_tax_deduction_file)
          this.$set(this.survey, 'last_job_ndfl2_file', data.last_job_ndfl2_file)
          this.$set(this.survey, 'diploma_file', data.diploma_file)
        } else {
          if (stop)
            return
          await UserSurveysResource.store({user_id: this.user.id})
          this.loadSurvey(true)
        }

      } catch (e) {
        console.log(e)
      }
      this.loading(false)
    },
    async saveAll() {
      try {
        for (let fileObj of Object.entries(this.survey)) {
          if (fileObj[1]?.id || fileObj[0] == 'id' || fileObj[0] == 'undefined')
            continue
          if(!fileObj[1])
            fileObj[1] = null
          if (Array.isArray(fileObj[1])) {
            if (fileObj[1][0]?.id) {
              continue
            }
          }
          await this.saveSurvey(fileObj[1], fileObj[0])
        }
        notify.show({message: 'Сохранено'})
        this.$emit('close')
      } catch (e) {
        console.log(e)
        notify.show({message: `Ошибка, ${e.response.data.message}`, color: 'error'})
      }

    },
    async saveSurvey(file, key) {
      this.loading()
      let res1 = []
      try {
        if (file && file?.length !== 0) {
          if(file.length <= 20 || !Array.isArray(file)) {
            let formData = new FormData()
            if (Array.isArray(file)) {
              for (let diff_file of file) {
                formData.append('files[]', diff_file)
                formData.append('model', 'user_survey')
                formData.append('external_id', this.survey.id)
              }
            } else {
              formData.append('files[]', file)
              formData.append('model', 'user_survey')
              formData.append('external_id', this.survey.id)
            }

            res1 = await MessagesResource.post('/api/files', formData);
          } else {
            let count = 0
            let formData = new FormData()
            for (let diff_file of file) {
              if(count == 19) {
                let bdFiles = await MessagesResource.post('/api/files', formData);
                res1.push(...bdFiles)
                formData = new FormData()
                count = 0
              }
              formData.append('files[]', diff_file)
              formData.append('model', 'user_survey')
              formData.append('external_id', this.survey.id)
              count++
            }
            if(count > 0) {
              let bdFiles = await MessagesResource.post('/api/files', formData);
              res1.push(...bdFiles)
            }
          }
        } else {
          res1 = [{id: null}]
        }

          if (res1?.data?.exception) {
            notify.show({message: 'Ошибка', color: 'error'});
            return;
          }

          let ids = res1.map(file => file.id);
          let file_key = key;
          key = key.includes('book_files') ? key.split('s')[0] + '_id' : key + '_id';
        if (res1.length > 1) {
          ids = JSON.stringify(ids);
        } else if (ids[0] === null) {
          ids = 0;
        } else {
          ids = parseInt(ids[0]);
        }
          const {data} = await UserSurveysResource.update(this.survey.id, {[key]: ids});
        console.log(ids)
        if(res1[0].id !== null){
          if (file_key.includes('book_files')) {
            this.survey[file_key] = res1.length > 1 ? [...res1] : [res1[0]];
          } else {
            this.survey[file_key] = res1.length > 1 ? [...res1] : res1[0];
          }
        }

          notify.show({message: 'Файл загружен'});
        }
      catch
        (e)
        {
          console.log(e);
        }
        this.loading(false)
      }
    ,
      onFileChange(file, key)
      {
        // Обработка изменения файла
        this.$set(this.survey, key, file);
      }
    ,
    },
    async mounted() {
      await this.loadSurvey()
    }
  })
</script>

<template>
  <v-dialog width="900px" v-model="dialog" @click:outside="$emit('close')">
    <v-form>
      <v-card>
        <v-card-title>
          Подтвердите данные
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-list>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold ">Паспорт</v-list-item-title>

            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            hint="*обязательно"
                            label="1 разворот"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.passport_1_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.passport_1_file, 'passport_1_file')"
                         :class="{'green white--text': survey?.passport_1_file?.id}" icon>
                    <v-icon>
                      {{ survey?.passport_1_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="2 разворот с пропиской"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.passport_2_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.passport_2_file, 'passport_2_file')"
                         :class="{'green white--text': survey?.passport_2_file?.id}" icon>
                    <v-icon>
                      {{ survey?.passport_2_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">Медицинская книжка</v-list-item-title>

            <p class="ml-5">Фотография и личные данные (2-3 страницы)</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="2-3 страницы"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.med_book_1_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.med_book_1_file, 'med_book_1_file')"
                         :class="{'green white--text': survey?.med_book_1_file?.id}" icon>
                    <v-icon>
                      {{ survey?.med_book_1_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <p class="ml-5">Последняя по дате отметка врача-профпатолога (Раздел V, на страницах 7-16)</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепить файл"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.med_book_2_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.med_book_2_file, 'med_book_2_file')"
                         :class="{'green white--text': survey?.med_book_2_file?.id}" icon>
                    <v-icon>
                      {{ survey?.med_book_2_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <p class="ml-5">Последняя по дате отметка о флюорографии (Раздел VI, на страницах 7-18)</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепить файл"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.med_book_3_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.med_book_3_file, 'med_book_3_file')"
                         :class="{'green white--text': survey?.med_book_3_file?.id}" icon>
                    <v-icon>
                      {{ survey?.med_book_3_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <p class="ml-5">Последняя по дате отметка о профессиональной подготовке и аттестации (Раздел XII, страница
              28)</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепить файл"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.med_book_4_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.med_book_4_file, 'med_book_4_file')"
                         :class="{'green white--text': survey?.med_book_4_file?.id}" icon>
                    <v-icon>
                      {{ survey?.med_book_4_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">ИНН</v-list-item-title>

            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Фото\скан документа"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.inn_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.inn_file, 'inn_file')"
                         :class="{'green white--text': survey?.inn_file?.id}" icon>
                    <v-icon>
                      {{ survey?.inn_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">СНИЛС</v-list-item-title>

            <p class="ml-5">Фото лицевой стороны СНИЛСа</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепить файл"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.snils_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.snils_file, 'snils_file')"
                         :class="{'green white--text': survey?.snils_file?.id}" icon>
                    <v-icon>
                      {{ survey?.snils_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">СТД-Р</v-list-item-title>
            <p class="ml-5">
              обязательная справка при приеме на работу (ее можно получить на портале Госуслуг: <a
                href="https://sfr.gov.ru/branches/primorye/news/~2021/10/26/232628#:~:text=%D0%9D%D0%B0%20%D0%BF%D0%BE%D1%80%D1%82%D0%B0%D0%BB%D0%B5%20%D0%B3%D0%BE%D1%81%D1%83%D1%81%D0%BB%D1%83%D0%B3%20%D0%B2%20%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D0%B5,%D1%84%D0%BE%D1%80%D0%BC%D0%B8%D1%80%D1%83%D0%B5%D1%82%D1%81%D1%8F%20%D0%B2%20%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D0%B5%20%D1%80%D0%B5%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8."
                target="_blank">ссылка на сайт</a>, на котором написано, как ее получить)
              или ТРУДОВАЯ КНИЖКА (все страницы!)
            </p>
            <v-list-item class="">
              <v-row>
                <v-col cols="12">
                  <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                                label="СТД-Р"
                                chips
                                deletable-chips
                                dense
                                @change="onFileChange"
                                v-model="survey.std_r_file"
                  >
                    <template v-slot:append>
                      <v-btn small
                             @click="saveSurvey(survey?.std_r_file, 'std_r_file')"
                             :class="{'green white--text': survey?.std_r_file?.id}"
                             icon
                      >
                        <v-icon>
                          {{ survey?.std_r_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                                label="Трудовая книжка"
                                multiple
                                chips
                                deletable-chips
                                dense
                                @change="onFileChange"
                                v-model="survey.work_book_files"
                  >
                    <template v-slot:append>
                      <v-btn small
                             @click="saveSurvey(survey?.work_book_files, 'work_book_files')"
                             :class="{'green white--text': survey?.work_book_files?.some(el => el?.id)}"
                             icon
                      >
                        <v-icon>
                          {{ survey?.work_book_files?.some(el => el?.id) ? 'mdi-check' : 'mdi-send-outline' }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-list-item>

            <template>
              <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">Военный билет (обязательно)
              </v-list-item-title>

              <p class="ml-5">Фото\скан всех страниц, от начала до конца</p>
              <v-list-item class="">
                <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                              label="Прикрепите документы"
                              hint="*обязательно"
                              chips deletable-chips persistent-hint
                              multiple dense @change="onFileChange" v-model="survey.war_book_files">
                  <template v-slot:append>
                    <v-btn small @click="saveSurvey(survey?.war_book_files, 'war_book_files')"
                           :class="{'green white--text': survey?.war_book_files?.some(el => el?.id)}" icon>
                      <v-icon>
                        {{ survey?.war_book_files?.some(el => el?.id) ? 'mdi-check' : 'mdi-send-outline' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-file-input>
              </v-list-item>
            </template>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">Реквизиты банковского счета в
              Сбербанк
            </v-list-item-title>
            <p class="ml-5">
              Реквизиты вашей карты, куда будет начисляться зарплата (если карты Сбербанка нет, ее необходимо сделать
              либо самостоятельно, либо через бухгалтерию организации). Реквизиты карты можно
              <a href="http://www.sberbank.ru/ru/person/help/transfer_faq/rekvizity#:~:text=%D0%9F%D0%B5%D1%80%D0%B5%D0%B9%D0%B4%D0%B8%D1%82%D0%B5%20%D0%BF%D0%BE%20%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B5%20%D0%B8%D0%BB%D0%B8%20%D0%BE%D1%82%D0%BA%D1%80%D0%BE%D0%B9%D1%82%D0%B5,%D0%BC%D0%B5%D1%81%D1%81%D0%B5%D0%BD%D0%B4%D0%B6%D0%B5%D1%80%D0%B5%20%D0%B8%D0%BB%D0%B8%20%D0%BF%D0%BE%20%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%BF%D0%BE%D1%87%D1%82%D0%B5."
                 target="_blank">скачать в мобильном приложении банка Сбербанк</a>
            </p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепите документ"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.bank_cred_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.bank_cred_file, 'bank_cred_file')"
                         :class="{'green white--text': survey?.bank_cred_file?.id}" icon>
                    <v-icon>
                      {{ survey?.bank_cred_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3  text-wrap text-break font-weight-bold">Диплом о среднем/высшем образовании
              (для менеджера и/или управляющего)
            </v-list-item-title>

            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Фото\скан документа" chips deletable-chips dense @change="onFileChange"
                            v-model="survey.diploma_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.diploma_file, 'middle_diploma_file')"
                         :class="{'green white--text': survey?.diploma_file?.id}" icon>
                    <v-icon>
                      {{ survey?.diploma_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">Заявление на прием на работу,
              написанное от руки
            </v-list-item-title>
            <p class="ml-5">
              (не выбрасываем, передаем управляющему).
              ДАТА ПРИЕМА: в заявлении дата приема указывается следующим образом: с даты начала работы, соответственно,
              если начали работать с 01.01.2023 пишите «Прошу Вас принять меня с 01.01.2023 на должность... и т.д.». Вы
              должны сразу создать обращение на прием (с первого дня работы), так как есть 7 дней на оформление.
              <b>ВАЖНО</b>: если вы затянете с созданием обращения - придется переписывать заявление на другую дату (то
              есть вы не будете трудоустроены с первого дня работы и оплату за эти дни не получите
              <br>
              На полную ставку на основное место работы: <br>
              Ставрополь 1: <a target="_blank">https://drive.google.com/file/d/1kbakbtiSs0LQIJD2vw-ifCrly0WZJ3cn/view?usp=sharing</a><br>
              Ставрополь 2: <a target="_blank">https://drive.google.com/file/d/1wDAoSRZcNbhmQgcQdWsgI9mT-Hk9Giuo/view?usp=sharing</a><br>
              Ставрополь 3: <a target="_blank">https://docs.google.com/document/d/1tXsv83fIP3HfXRxyI9jZmkDrqOrRtvJHAIjYENgU-3U/edit</a><br>
              Абинск: <a target="_blank">https://drive.google.com/file/d/1DylEM8A4jzWe2v6EF83hgCsPldC_Ouum/view?usp=sharing</a><br>
              Кореновск: <a target="_blank">https://drive.google.com/file/d/1Y10MrtngJdUtuFp5m81-aRldu1sIjQiw/view?usp=sharing</a><br>
              Ессентуки: <a target="_blank">https://drive.google.com/file/d/1Hgqo8-nxfea1q6qIhfmOdkn6qneWRELT/view?usp=sharing</a><br>
              Тимашевск: <a target="_blank">https://docs.google.com/document/d/1odHnBvl-vfd9MpmlpmSwtuFuUstX3VQZ97gk-XmAPSo/edit</a><br>
              <br>
              На неполную ставку <b class="red--text">(ОБРАТИТЕ ВНИМАНИЕ: если это ваше первое рабочее место, то в
              заявлении НЕ УКАЗЫВАЙТЕ
              фразу «по внешнему совместительству»)</b>:<br>
              Ставрополь 1: <a target="_blank">https://drive.google.com/file/d/1MO8AzWZWrEj9YlTXHur33YTT2wki6YSD/view?usp=sharing</a><br>
              Ставрополь 2: <a target="_blank">https://drive.google.com/file/d/1iaaQk2ZKcXjvYThkEKySJG9jwcer1sf1/view?usp=sharing</a><br>
              Ставрополь 3: <a target="_blank">https://docs.google.com/document/d/1SxjUD2206zwahjuEOngNGC6Bp-dp7-TMUSb22Cy2Qqc/edit</a><br>
              Абинск: <a target="_blank">https://drive.google.com/file/d/1t97eQG_YGN28yT0PApuGnS-KRn-zQtt2/view?usp=sharing</a><br>
              Кореновск: <a target="_blank">https://drive.google.com/file/d/13YMCzJh1PVbQqjFnsWlg7InXlhrdHRca/view?usp=sharing</a><br>
              Ессентуки: <a target="_blank">https://drive.google.com/file/d/1BEsrHrjEaN5aLfnP9SbrheCt4S8vfDeb/view?usp=sharing</a><br>
              Тимашевск: <a target="_blank">https://docs.google.com/document/d/1vZ8O8Kzi9bFUqlbzcgQ7VOxaI7Sw1sF09ZLLcLgOlaM/edit</a><br>

            </p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Фото\скан документа"
                            hint="*обязательно"
                            chips deletable-chips dense persistent-hint
                            @change="onFileChange" v-model="survey.work_application_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.work_application_file, 'work_application_file')"
                         :class="{'green white--text': survey?.work_application_file?.id}" icon>
                    <v-icon>
                      {{ survey?.work_application_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <template>
              <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">Для лиц младше 18 лет
              </v-list-item-title>

              <v-list-item class="">
                <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                              label="Согласие родителей" chips deletable-chips dense
                              @change="onFileChange" v-model="survey.parent_approval_file">
                  <template v-slot:append>
                    <v-btn small @click="saveSurvey(survey?.parent_approval_file, 'parent_approval_file')"
                           :class="{'green white--text': survey?.parent_approval_file?.id}" icon>
                      <v-icon>
                        {{ survey?.parent_approval_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-file-input>
              </v-list-item>
            </template>

            <v-list-item-title class="mb-3 text-wrap text-break font-weight-bold">По желанию</v-list-item-title>
            <p class="ml-5">При наличии у вас детей, вы можете получить налоговый вычет. Приложите скан/фото:</p>
            <p class="ml-6">- Заполненное от руки заявление на налоговый вычет (<a
                href="https://drive.google.com/file/d/1uSnUTr3NZH5GxE_lixm8E8WqIN1EO48D/view?usp=sharing"
                target="_blank">перейдите по ссылке</a>):</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Заявление на налоговый вычет" chips
                            deletable-chips dense @change="onFileChange" v-model="survey.child_tax_deduction_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.child_tax_deduction_file, 'child_tax_deduction_file')"
                         :class="{'green white--text': survey?.child_tax_deduction_file?.id}" icon>
                    <v-icon>
                      {{ survey?.child_tax_deduction_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <p class="ml-6">- Свидетельство о рождении детей до 18 лет или до 24 лет (если учатся на очной форме
              обучения, при наличии справки из учебного заведения)</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Свидетельство о рождении детей"
                            chips deletable-chips dense @change="onFileChange" v-model="survey.child_birth_cert_files">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.child_birth_cert_files, 'child_birth_cert_files')"
                         :class="{'green white--text': survey?.child_birth_cert_files?.id}" icon>
                    <v-icon>
                      {{ survey?.child_birth_cert_files?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <p class="ml-5">Справка для расчета пособий за последние два года (Форма 182-н) - Справка 2-НДФЛ с
              предыдущей работы</p>
            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Прикрепите документ"
                            chips deletable-chips dense @change="onFileChange" v-model="survey.last_job_ndfl2_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.last_job_ndfl2_file, 'last_job_ndfl2_file')"
                         :class="{'green white--text': survey?.last_job_ndfl2_file?.id}" icon>
                    <v-icon>
                      {{ survey?.last_job_ndfl2_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

            <v-list-item class="">
              <v-file-input accept="image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                            label="Диплом об образовании" chips deletable-chips dense @change="onFileChange"
                            v-model="survey.diploma_file">
                <template v-slot:append>
                  <v-btn small @click="saveSurvey(survey?.diploma_file, 'diploma_file')"
                         :class="{'green white--text': survey?.diploma_file?.id}" icon>
                    <v-icon>
                      {{ survey?.diploma_file?.id ? 'mdi-check' : 'mdi-send-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-file-input>
            </v-list-item>

          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="green white--text" @click="saveAll">Сохранить</v-btn>
          <v-btn class="red white--text" @click="$emit('close')">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style scoped lang="sass">

.v-list-item
  margin-bottom: 15px

</style>
